:root{
  --TituloPrincipal: 35px;
    --ParrafoEscritorio: 18px;
    --TituloMovil: 22px;
    --ParrafoMovil: 16px;
    --TextoColor: #333;
    --Negritas: Bold;
    --VerdePKT1: #3baf29;
    --AzulPKT1: #151b4e;
    --AzulHeader: #2f3768;
    --Rojo: #f40000;
    --Blanco: #fff;
    --Negro: #000;
    --Fondo: #eff0f2;
    --HeaderTabla:#5B5F83;
    --BotonDesactivado: rgba(149, 151, 167, 0.24);
}


*{
  font-family: Amiko, sans-serif;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
